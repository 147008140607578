import React, { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import InfiniteScroll from "react-infinite-scroll-component";
import { MenuItem } from "@szhsin/react-menu";

import Container from "atoms/Container";
import Input from "atoms/Input";
import ReactSelect from "atoms/forms/ReactSelect";
import EcommSearchMenu from "./EcommSearchMenu";
import SkeletonLoader from 'atoms/skeleton/SkeletonLoader';
import UserCard from "molecules/UserCard";

import IconSearch from "atoms/SvgIcons/IconSearch.svg";
import IconLocationGrey from "atoms/SvgIcons/IconLocationGrey.svg";

import { getLeadCities } from "services/my-leads";
import { getAllDoctors } from 'services/ayurvedic-doctor.service'

import { formatLocationText } from 'utils';
import { useUserContext } from "@/components/context/UserContext";
import { getAllClinicsV1 } from "services/clinic.service";

const bucketUrl = process.env.NEXT_PUBLIC_BUCKET_URL;


const IndividualsSearchSection = React.forwardRef(({ className, search = "", searchLocation = "India", menuWidth = 0, type = "doctor", ...props }, ref) => {
    const limit = 25;

    const [searchText, setSearchText] = useState(search);
    const [location, setLocation] = useState(searchLocation);
    const [searchResults, setSearchResults] = useState([]);
    const [offset, setOffset] = useState(0);
    const [cityList, setCityList] = useState([]);
    const [isShowSearch, setIsOpenSearch] = useState(false);
    const [isFetchSearch, setIsFetchSearch] = useState(false);

    const router = useRouter();

    const anchorRef = useRef(null);

    const { isShowSearchResult, setIsShowSearchResult } = useUserContext();
    const locationSlug = loc => {
        const locationPart = loc?.split(',')[0];
        return locationPart.replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase();
    };

    const getDoctor = async ({ search, city, isInitialFetch = false }) => {
        const response = await getAllDoctors({ page: 1, limit, search: search, city: city != "India" ? city : "" })
            .then((response) => {
                setSearchResults([...response?.entity?.rows || []]);
                setOffset(isInitialFetch ? limit : offset + limit);
                setIsFetchSearch(false);
            })
            .catch(error => {
                throw error;
            });
    };

    const getClinic = async ({ search, city, isInitialFetch = false }) => {
        const response = await getAllClinicsV1({ skip: 0, limit: limit, clinicSearch: search, locationSearch: city != "India" ? city : "", state: '', services: '', fee: '', feeResult: '', clinicIds: "", clinicSlugs: "", isSmartClinic: "" })

            .then((response) => {
                setSearchResults([...response?.entity?.rows || []]);
                setOffset(isInitialFetch ? limit : offset + limit);
                setIsFetchSearch(false);
            })
            .catch(error => {
                throw error;
            });
    };

    const onSearchEnter = (e) => {
        if (type == "clinic") {
            if (e.key === 'Enter' && searchText) {
                setIsShowSearchResult(false);
                if (location === "India") {
                    router.push(`/ayurveda/clinics/${searchText !== "" ? `?search=${searchText}` : ""}`);
                } else if (location && location !== "India") {
                    router.push(`/ayurveda/clinics/${locationSlug(location) ?? router?.query?.location}/${searchText !== "" ? `?search=${searchText}` : ""}`);
                } else {
                    router.push(`/ayurveda/clinics/${searchText !== "" ? `?search=${searchText}` : ""}`);
                }
            }
        }
        else {

            if (e.key === 'Enter' && searchText) {
                setIsShowSearchResult(false);
                if (location === "India") {
                    router.push(`/ayurveda/doctors/${searchText !== "" ? `?search=${searchText}` : ""}`);
                } else if (location && location !== "India") {
                    router.push(`/ayurveda/${locationSlug(location) ?? router?.query?.location}/doctors${searchText !== "" ? `?search=${searchText}` : ""}`);
                } else {
                    router.push(`/ayurveda/doctors/${searchText !== "" ? `?search=${searchText}` : ""}`);
                }
            };
        }
    }

    const handleClickOnSearchBox = () => {
        if (searchText) {
            setIsOpenSearch(true);
        } else {
            setIsOpenSearch(true);
        }
    }

    useEffect(() => {
        getLeadCities()
            .then(response => {
                if (response.status) {
                    setCityList([
                        { label: "India", value: "India" },
                        ...response?.entity.map(item => {
                            const city = formatLocationText(`${item?.city}${item?.state ? `, ${item?.state}` : ""}`);
                            return {
                                label: city,
                                value: city,
                            }
                        })
                    ]);
                };
            })
            .catch(error => {
                console.log(error);
                throw error;
            });
    }, []);

    useEffect(() => {
        let trimmedText = searchText.trim()
        if (trimmedText == "") return
        let city = location ? location : ''
        let delayDebounceFn = setTimeout(async () => {
            if (trimmedText !== '') setIsOpenSearch(true);
            setIsFetchSearch(true);
            type == 'doctor' ?
                getDoctor({ search: trimmedText, city: city, isInitialFetch: true }) :
                getClinic({ search: trimmedText, city: city, isInitialFetch: true });


        }, 800);
        return () => clearTimeout(delayDebounceFn);
    }, [searchText, location]);


    return (
        <div className={`${className} z-1`}>
            <div className='flex gap-3 h-10'>
                <ReactSelect
                    className="h-full w-5/12 xxs:w-[104px] sm:w-[115px] lg:w-[198px]"
                    customZIndex={50}
                    onChange={(data) => {
                        setLocation(data?.value);
                    }}
                    defaultValue={{ label: searchLocation ?? "India", value: searchLocation ?? "India" }}
                    options={cityList}
                    dropdownHeight={40}
                    selectedValueFontSize={14}
                    optionsFontSize={12}
                    optionPadding="10px 12px"
                    menuWidth={menuWidth}
                    error={""}
                />
                <div className="flex h-full w-11/12 xxs:w-[266px] sm:w-[325px] lg:w-[450px]" ref={anchorRef}>
                    <Input
                        className="w-full h-full"
                        placeholderClass="-mt-0.75 text-xs"
                        type="text"
                        placeholder={type == "clinic" ? "Search by Clinic Name" : "Search by Doctor, Disease, Symptoms"}
                        iconIndicator={<IconSearch className="flex-shrink-0 w-5 stroke-gray-400 ml-2 -mr-2" />}
                        id="doctorSearch"
                        isRequiredField={true}
                        containerClass="w-full h-full"
                        defaultValue={search}
                        onChange={(value) => {
                            if (isShowSearchResult == false) {
                                setIsShowSearchResult(true);
                            }
                            setSearchText(value)
                        }}
                        onKeyPress={onSearchEnter}
                    />
                </div>
            </div>

            {(searchText && isShowSearchResult != false) ?
                <EcommSearchMenu className='rounded-b-2xl' subClass='min-h-[200px] max-h-[374px]' isShowSearch={isShowSearch} setIsOpenSearch={setIsOpenSearch} title={`${type=='clinic'? `Search Clinics`:`Search Doctors`}`} anchorRef={anchorRef}>

                    {isFetchSearch ?
                        <SkeletonLoader type="userShortSummery" length={3} isCircularImage={true} minHeight={71} />
                        :
                        searchResults?.length ?
                            <>
                                <InfiniteScroll dataLength={searchResults?.length} next={() => getDoctor({ search: searchText, city: location ? location?.replace(", ", "-") : "" })} hasMore={true} scrollableTarget="scrollableDiv">
                                    {
                                        type == "doctor" ?
                                            searchResults?.map((item) => (
                                                <MenuItem className='menu__searchResults' key={item?.id}>
                                                    <UserCard
                                                        className=""
                                                        href={item?.href}
                                                        avatar={`${bucketUrl}${item?.profilePic ? item.profilePic : '/images/icon_doctor.svg'}`}
                                                        name={item?.fullNameSalutation}
                                                    />
                                                </MenuItem>
                                            ))
                                            :
                                            searchResults?.map((item) => (
                                                <MenuItem className='menu__searchResults' key={item?.id}>
                                                    <UserCard
                                                        className=""
                                                        href={item?.href}
                                                        avatar={`${bucketUrl}${item?.preview_image ? item.preview_image : '/images/icon-clinics.svg'}`}
                                                        name={item?.clinic_name}
                                                    />
                                                </MenuItem>
                                            ))
                                    }

                                </InfiniteScroll>
                            </>
                            : <div className='text-2xl text-gray-400 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 '>No result found</div>
                    }

                </EcommSearchMenu> : null
            }
        </div>
    );
});

export default IndividualsSearchSection;